<template>
  <div class="productBox">
    <ul>
      <li>
        <el-card class="box-card">
          <h5>期魔方量化平台</h5>
          <p>
            期魔方量化投研平台是由四川赤壁量化科技有限公司自主研发，专为国内期货市场打造的全能量化交易与研究平台。它集成了市场行情分析、量化策略回测、数据分析、风险管理以及机器学习等多项强大功能，具备高度可扩展性，能够满足不同用户的多样化需求。
          </p>
          <div class="ms">
            <el-icon><Monitor /></el-icon>配置要求:Windows10及以上系统，4核8G以上配置
          </div>
          <div class="ms">
            <el-icon><Paperclip /></el-icon>版本信息:v1.0.0
          </div>
          <div class="btns">
            <el-button type="primary" @click="uplowd">软件下载</el-button>
            <el-button type="primary" @click="toHelpDoc">使用说明</el-button>
          </div>
        </el-card>
      </li>
    </ul>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  data() {
    return {
      config: '信息获取中…',
      version: '信息获取中…'
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    uplowd() {
      location.href = "https://academy.qmfquant.com/qimofang.zip";
    },
    toHelpDoc() {
      //location.href = "https://www.qmfquant.com/app/index.html";
      window.open("https://www.qmfquant.com/app/index.html")
    },
    get() {
      // axios.get(`https://academy.qmfquant.com/api/getQmfs`).then((response) => {
      //   this.config = response.data['config']
      //   this.version = response.data['version']
      //   console.log('[config]',this.config,'[version]',this.version)
      // });
    },
  },
};
</script>

<style>
@media (min-width: 1200px) {
  .productBox {
    width: 1200px;
    min-height: 534px;
    margin: 0 auto;
    padding-top: 30px;
  }
  .productBox ul li {
    width: 50%;
  }
  .productBox ul li h5 {
    font-size: 20px;
    color: #301330;
    margin-bottom: 10px;
  }
  .productBox ul li p {
    font-size: 14px;
    color: #665f66;
    line-height: 25px;
  }
  .productBox ul li .ms {
    font-size: 14px;
    color: #665f66;
    margin-top: 15px;
    display: flex;
    align-items: center;
  }
  .productBox ul li div .el-icon {
    color: #135de9;
    font-size: 17px;
    margin-right: 5px;
  }
  .productBox ul li .btns {
    margin-top: 20px;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .productBox {
    width: 98vw;
    min-height: 100%;
    margin: 0 auto;
    padding-top: 2rem;
  }
  .productBox ul li {
    width: 50%;
  }
  .productBox ul li h5 {
    font-size: 1.4rem;
    color: #301330;
    margin-bottom: 10px;
  }
  .productBox ul li p {
    font-size: 0.9rem;
    color: #665f66;
    line-height: 1.5rem;
  }
  .productBox ul li .ms {
    font-size: 0.9rem;
    color: #665f66;
    margin-top: 0.9rem;
    display: flex;
    align-items: center;
  }
  .productBox ul li div .el-icon {
    color: #135de9;
    font-size: 0.95rem;
    margin-right: 0.3rem;
  }
  .productBox ul li .btns {
    margin-top: 1rem;
  }
}
@media (max-width: 768px) {
  .productBox {
    width: 98vw;
    margin: 0 auto;
    padding-top: 1rem;
    margin-bottom: 1rem;
  }
  .productBox ul li {
    width: 100%;
  }
  .productBox ul li h5 {
    font-size: 1rem;
    color: #301330;
    margin-bottom: 0.5rem;
  }
  .productBox ul li p {
    font-size: 0.7rem;
    color: #665f66;
    line-height: 1rem;
  }
  .productBox ul li .ms {
    font-size: 0.7rem;
    color: #665f66;
    margin-top: 0.9rem;
    display: flex;
    align-items: center;
  }
  .productBox ul li div .el-icon {
    color: #135de9;
    font-size: 0.95rem;
    margin-right: 0.3rem;
  }
  .productBox ul li .btns {
    margin-top: 0.7rem;
  }
  .productBox ul li .btns .el-button {
    width: 4rem;
    font-size: 0.7rem;
    height: 1.5rem;
  }
}
</style>